
import { computed, ref, watch } from 'vue';

// icons
import { close, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
        IonGrid, IonCol, IonRow, IonSpinner, IonLabel, IonButtons, IonButton, IonIcon,
        onIonViewDidEnter, onIonViewWillLeave, loadingController, } from '@ionic/vue';
import LogoImg from "@/components/LogoImg.vue";
import Slides from "@/components/slides/Slides.vue";

// compsables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { useRouter } from 'vue-router';

export default {
  name: 'LandingPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
                IonGrid, IonCol, IonRow, IonSpinner, IonLabel, IonButtons, IonButton, IonIcon,
                LogoImg, Slides, },
  setup() {
    // methods
    const { t, locale, } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { presentAlert, openLoginModal, uniqueId } = utils();

    // state variables
    const currUser = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loadingUserData = computed(() => store.state.loadingAppUserData);
    const loadingAppPublicData = computed(() => store.state.loadingAppPublicData);
    const settings = computed(() => store.state.settings);
    const topBanners = computed(() => store.getters.landingTopBanners);
    const middleBanners = computed(() => store.getters.landingMiddleBanners);
    const bottomBanners = computed(() => store.getters.landingBottomBanners);

    const checkLoggedIn = () => {
      if (userLoggedIn.value) return true;
      openLoginModal();
      return false;
    }

    // WhatsApp Sticker Modal
    const goToStickerDetailPage = async (isForDIYProduct = false) => {
      if (checkLoggedIn()) {
        const loading = await loadingController.create({});
        await loading.present();
        const newUserStickerId = uniqueId(), name = t('untitledSticker');
        //StickerService.upsertUserSticker({ newUserStickerId, name, }); // Insert Sticker Record to DB
        store.commit('upsertUserSticker', { name, id: newUserStickerId });
        //router.replace(`/stickers/${newUserStickerId}`);
        router.push({ name: 'StickerDetailPage', params: { id: newUserStickerId }, state: { parentPath: '/', isForDIYProduct } })
        loading.dismiss();
      }
    }

    return {
      // methods
      t, goToStickerDetailPage,

      // icons
      close,
      
      // variables
      locale, loadingAppPublicData, loadingUserData,
      currUser, userLoggedIn,
      settings,
      topBanners, middleBanners, bottomBanners,
    }
  },
}
